<template>
  <pz-form-flujo
    v-model="formulario2['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form2_persona.UY.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario2"
      status-icon
      :rules="rules_formulario2"
    >
      <pz-direction
        v-model="formulario2.direccion"
        viewline
      />
      <label>{{ $t('forms_flujo.form2_persona.BNF.datos_particulares') }}</label>
      <hr
        class="pz-dotted"
        style="margin-top: 1px"
      >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="tipo_vivienda"
            :label="$t('forms_flujo.form2_persona.UY.property_type')"
            :error="errors.get('tipo_vivienda')"
          >
            <el-select
              v-model="formulario2.tipo_vivienda"
              clearable
              :placeholder="$t('forms_flujo.form2_persona.UY.property_type')"
            >
              <el-option
                v-for="item in properties"
                :key="item.id"
                :label="item.tipo"
                :value="item.id"
                :placeholder="$t('forms_flujo.form2_persona.UY.property_type')"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="email"
            :label="$t('forms_flujo.form2_persona.UY.email')"
            :error="errors.get('email')"
          >
            <el-input
              v-model="formulario2.email"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.UY.email')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="comoseentero"
            :label="$t('forms_flujo.form2_persona.UY.how_find_out')"
            :error="errors.get('comoseentero')"
          >
            <el-select
              v-model="formulario2.comoseentero"
              clearable
              :placeholder="$t('forms_flujo.form2_persona.UY.how_find_out')"
            >
              <el-option
                v-for="item in formas_de_enterarse"
                :key="item.id"
                :label="item.forma"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>
    import { type} from "@/commons/utils/Rules";
    import {
        getDepartamentos,
        getTipoViviendas,
        getFormasDeEnterarse
    } from "@/routes/api/resources";
    import baseform2 from '../baseForm2'
    import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
    import PzDirection from "@/commons/forms/Fields/PzDirection";

    export default {
        name: 'PzPersonFormExtPrestama',
        components: {PzFormFlujo, PzDirection},
        mixins: [baseform2],
        data() {
            return {
                properties: [],
                formas_de_enterarse: [],
                provincias: [],
                formulario2: {
                    direccion: {},
                    provincia: '',
                    email: '',
                    ref_empresa: process.env.COMPANY_KEY,
                    tipo_documento: process.env.IDE_DEFAULT,
                    comoseentero: '',
                    tipo_vivienda:''

                }
                ,
                disable: true,
                rules_formulario2:
                    {
                        email: [
                            type({field: '', type: 'email'})
                        ],
                    },
                pickerOptions1: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                },
            };
        },
        created: function () {
        },
        beforeMount() {
            var viv = getTipoViviendas().then((res) => {
                this.properties = res.data;
            }).catch(() => {
                this.properties = [];
            });
            var dep = getDepartamentos().then((res) => {
                this.provincias = res.data;
            }).catch(() => {
                this.provincias = [];
            });
            var form = getFormasDeEnterarse().then((res) => {
                this.formas_de_enterarse = res.data;
            }).catch(() => {
                this.formas_de_enterarse = [];
            });

            Promise.all([viv, dep, form]).then(() => {

            }).finally(() => {this.$store.dispatch('loader/down',{trigger:this.$options.name})});
        },
        methods: {},
    }
</script>

